<template>
  <div>
    <v-chart :forceFit="true" :padding="padding"  :height="height" :data="dataList" :scale="scale">
      <v-tooltip />
      <v-legend :custom="legendCustom" :allow-all-canceled="legendAllowAllCanceled" :items="legendItems" :on-click="legendOnClick" />
      <v-axis  :grid="axisGrid" :label="axisLabel" data-key="土方" />
      <v-bar position="月份*工单" color="#247EFF" />
      <v-line position="月份*土方" color="rgb(14, 243, 56)" :size="gemoSize" /> 
      <v-point shape="circle" position="月份*土方" color="rgb(14, 243, 56)" :size="gemoSize" />
    </v-chart>
  </div>
</template>

<script>
const scale = [
  {
    dataKey: '土方',
    alias:'土方(单位:方)',
  },
  {
    dataKey: '工单',
    alias:'工单(单位:条)',
  }
]

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(val) {
        this.dataList = val
      }
    }
  },
  data() {
    return {
      scale,
      height: 400,
      dataList: [],
      padding: [20, 40, 60, 40],
      axisLabel: {
      },
      axisGrid: null,
      gemoSize: 3,
      legendCustom: true,
      legendAllowAllCanceled: true,
      legendItems: [
        { value: '工单', marker: { symbol: 'square', fill: '#247EFF', radius: 5, lineWidth: 3 } },
        { value: '土方', marker: { symbol: 'hyphen', stroke: 'rgb(14, 243, 56)', radius: 5, lineWidth: 3 } }
      ],
      legendOnClick: (ev, chart) => {
        const item = ev.item
        const value = item.value
        const checked = ev.checked
        const geoms = chart.getAllGeoms()
        for (let i = 0; i < geoms.length; i++) {
          const geom = geoms[i]
          if (geom.getYScale().field === value) {
            if (checked) {
              geom.show()
            } else {
              geom.hide()
            }
          }
        }
      }
    }
  }
}
</script>
<style>
.a {
  color: rgb(14, 243, 56);
}
</style>

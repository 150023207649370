<template>
  <div class="data-item">
    <div class="data-item-title flex">
      <span>{{data.title}}</span>
      <el-tooltip
        class="item"
        effect="dark"
        :content="data.tip"
        placement="top-start"
      >
        <i style="color:#bab4b4;width: 20px;" class="el-icon-question"></i>
      </el-tooltip>
    </div>
    <div class="data-item-num">{{data.total}}</div>
    <div v-if="data.isProject" class="data-item-detail flex">
      <span>已开工:</span>{{data.openCount}} <span>未开工:</span>{{data.notOpenCount}}
    </div>
    <div v-if="!data.isProject" class="data-item-detail flex">
      <span>白班:</span>{{data.dayCount}} <span>夜班:</span>{{data.nightCount}}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type:Object,
      default:()=>{}
    }
  }
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.data-item {
  width: 175px;
  text-align: center;
  line-height: 35px;
  &-title {
    width: 175px;
    font-size: 17px;
    font-weight: 650;
  }
  &-num {
    font-weight: 700;
    font-size: 27px;
    color: #247EFF;
  }
  &-detail {
    font-size: 14px;
    margin-right:15px;
  }
}

</style>

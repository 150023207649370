<template>
  <div v-if="dataList.length > 0">
    <v-chart :forceFit="true" :padding="padding" :height="height" :data="data" :scale="scale">
      <v-tooltip :showTitle="false" dataKey="item*percent" />
      <v-axis />
      <v-legend dataKey="item" />
      <v-pie position="percent" color="item" :vStyle="pieStyle" :label="labelConfig" />
      <v-coord type="theta" :radius="0.75" :innerRadius="0.6" />
      <v-guide type="text" :top="true" :position="['45%', '50%']" :content="guidContent" :style="guidStyle"> </v-guide>
    </v-chart>
  </div>
  <div v-else>
    <el-empty :image-size="170"></el-empty>
  </div>
</template>

<script>
const DataSet = require('@antv/data-set')
const scale = [
  {
    dataKey: 'percent',
    min: 0,
    formatter: '.0%'
  }
]
const dv = new DataSet.View()
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      default: 0
    }
  },
  watch: {
    dataList: {
      immediate: true,
      handler(val) {
        this.init()
      }
    }
  },
  data() {
    return {
      data: null,
      dv: dv,
      scale,
      padding: [20, 20, 60, 20],
      height: 320,
      guidStyle: {
        fontSize: '30',
        fill: '#262626',
        textAlign: 'center'
      },
      guidContent: '总：0',
      pieStyle: {
        stroke: '#fff',
        lineWidth: 1
      },
      labelConfig: [
        'percent',
        {
          formatter: (val, item) => {
            return item.point.item + ': ' + val
          }
        }
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.guidContent = '总:' + this.total
      this.dv.source(this.dataList)
      this.dv.transform({
        type: 'percent',
        field: 'count',
        dimension: 'item',
        as: 'percent'
      })
      this.data = this.dv.rows
    }
  }
}
</script>

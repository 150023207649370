import request from '@/utils/http'

// 首页数据查询
export function previewData(params) {
  return request({
    url: '/pc/view/boss',
    method: 'get',
    params
  })
}

// 获取工单趋势
export function orderTrend(params) {
  return request({
    url: '/pc/view/trend/boss',
    method: 'get',
    params
  })
}


